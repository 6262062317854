import cx from 'classnames';
import { useRouter } from 'next/router';

import { Button } from '@sb/design-system';

import { SBLogo } from './SBLogo';

interface ErrorPageLayoutProps {
  actionButton?: { label: string; link: string };
  message: string;
  statusCode?: number;
}

const ErrorPageLayout = ({
  actionButton,
  message,
  statusCode,
}: ErrorPageLayoutProps) => {
  const { push } = useRouter();

  return (
    <div
      className={cx(
        'tw-min-h-[100dvh]',
        'tw-pt-[120px]',
        'tw-pb-32',
        'tw-flex',
        'tw-flex-col',
        'tw-items-center',
        'tw-gap-24',
      )}
    >
      <header
        className={cx('tw-flex', 'tw-flex-col', 'tw-items-center', 'tw-gap-12')}
      >
        <SBLogo />
        <div className={cx('tw-heading-60')}>Oops!</div>
      </header>

      <main
        className={cx(
          'tw-flex',
          'tw-flex-col',
          'tw-items-center',
          'tw-gap-16',
          'tw-max-w-512',
          'tw-text-center',
        )}
      >
        <p data-status-code={statusCode}>{message}</p>

        {actionButton && (
          <Button variant="Filled" onClick={() => push(actionButton.link)}>
            {actionButton.label}
          </Button>
        )}
      </main>
    </div>
  );
};

export default ErrorPageLayout;
