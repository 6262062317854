import { usePageRobotAndRoutine } from '@sbrc/hooks';

import FullPageLoader from '../FullPageLoader';
import RobotNotFoundError from '../visualizer-view-shared/RobotNotFoundError';

import HomeView from './HomeView';

export default function HomeViewLayout() {
  const { robot, routine } = usePageRobotAndRoutine();

  if (robot === undefined || routine === undefined) {
    return <FullPageLoader label="Loading home page" />;
  }

  if (robot === null) {
    return <RobotNotFoundError />;
  }

  return <HomeView robot={robot} routine={routine} />;
}
