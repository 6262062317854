import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { getEquipmentByKind } from '@sb/integrations/loaders/getEquipmentByKind';
import type { Robot } from '@sb/remote-control/types';
import { useRobotGripperState } from '@sbrc/hooks';
import { LINK_TO_MOVE_PAGE } from '@sbrc/utils';

import { EquipmentManagerModal } from '../equipment-manager';

interface HomeMoveRobotwProps {
  robot: Robot.ConvertedResponse;
}

export function GripperWidget({ robot }: HomeMoveRobotwProps) {
  const [isEquipmentModalOpen, setIsEquipmentModalOpen] = useState(false);

  const closeModal = () => setIsEquipmentModalOpen(false);

  const { push } = useRouter();

  const gripperState = useRobotGripperState({});
  const implementation = gripperState && getEquipmentByKind(gripperState?.kind);

  const openWidget = () =>
    push(
      `${LINK_TO_MOVE_PAGE}?openWidget=${implementation!
        .getDisplayName()
        .replace(/[\s/]/g, '')}`,
    );

  return (
    <>
      {!implementation || !implementation.HomeWidget ? (
        <div
          role="button"
          className="tw-col-span-2 tw-row-span-1 tw-flex tw-gap-12 tw-dark tw-rounded-[32px] tw-shadow-80 tw-p-16 tw-pl-24 tw-surface-primary"
          onClick={() => setIsEquipmentModalOpen(true)}
        >
          <div className="tw-flex-1 tw-flex tw-flex-col tw-justify-between tw-text-15 tw-pt-8">
            <div>
              <h3 className="tw-text-20 tw-font-medium tw-text-label-tertiary">
                No gripper
              </h3>
            </div>
            <div className="tw-mb-8 tw-text-label-tertiary">
              Add in Equipment
            </div>
          </div>
          <Image
            src="/images/grippers/NoGripper.png"
            alt="NoGripper"
            width="132"
            height="132"
          />
        </div>
      ) : (
        <div
          role="button"
          onClick={openWidget}
          className="tw-col-span-2 tw-row-span-1 tw-flex tw-gap-12 tw-dark tw-rounded-[32px] tw-shadow-80 tw-p-16 tw-pl-24 tw-surface-primary"
        >
          <implementation.HomeWidget implementation={implementation} />
        </div>
      )}

      <EquipmentManagerModal
        isOpen={isEquipmentModalOpen}
        onClose={closeModal}
        robot={robot}
      />
    </>
  );
}
