import cx from 'classnames';
import { useState } from 'react';

import { Button, Icon, Menu, MenuItem, RichSelect } from '@sb/design-system';
import type { Routine } from '@sb/remote-control/types';
import { Loader } from '@sb/ui/components';
import { useRoutineList } from '@sbrc/hooks';

import { NewRoutineMenuItems } from '../routine-list/new-routine/NewRoutineMenuItems';

import { HomeRoutineCard } from './HomeRoutineCard';

export default function HomeRoutineList() {
  const [valueFilter, setValueFilter] = useState('Recent');
  const [isSeeAll, setIsSeeAll] = useState(false);

  const routines = useRoutineList();

  if (!routines) {
    return (
      <div className="tw-col-span-2 tw-row-span-3">
        <Loader />
      </div>
    );
  }

  function getSortedRoutines(items: Array<Routine.Summary>) {
    if (valueFilter === 'Created') {
      return items.sort(
        (a, b) => b.createdAt.getTime() - a.createdAt.getTime(),
      );
    }

    if (valueFilter === 'Name') {
      return items.sort((a, b) => a.name.localeCompare(b.name));
    }

    return items.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
  }

  return (
    <div
      className={cx(
        'tw-col-span-2 tw-row-span-3 tw-dark tw-flex tw-flex-col tw-rounded-[32px] tw-shadow-80 tw-px-24 tw-transition-margin',
        isSeeAll
          ? '-tw-my-44 -tw-ml-16 -tw-mr-60 tw-z-10 tw-surface-secondary'
          : 'tw-surface-primary',
      )}
    >
      {/* header */}
      <div className="tw-flex tw-items-center tw-h-[64px] tw-justify-between">
        <h3 className="tw-text-20 tw-font-medium">Routines</h3>

        {!isSeeAll && (
          <RichSelect
            className="tw-text-label-secondary disabled:tw-bg-transparent"
            variant="Flat"
            isDark
            disabled={routines.length === 0}
            value={valueFilter}
            options={
              <Menu className="tw-surface-secondary">
                {[
                  { name: 'Recent' },
                  { name: 'Created' },
                  { name: 'Name' },
                ].map((item) => (
                  <MenuItem
                    key={item.name}
                    onClick={() => setValueFilter(item.name)}
                    secondaryIconKind={
                      item.name === valueFilter ? 'checkmark' : 'blank'
                    }
                    iconKindClassName="tw-text-orange"
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Menu>
            }
          >
            <span className="tw-flex tw-gap-4">
              <Icon kind="filter" className="tw-icon-22" />
              <span>{valueFilter}</span>
            </span>
          </RichSelect>
        )}

        {isSeeAll && (
          <Button variant="Flat" onClick={() => setIsSeeAll(false)}>
            Done
          </Button>
        )}
      </div>

      {/* main list */}
      <div
        className={cx(
          'tw-flex-1',
          'tw-relative',
          isSeeAll
            ? ' tw-max-h-[564px] tw-pb-12 tw-overflow-y-auto'
            : ' tw-max-h-[404px] tw-overflow-hidden',
        )}
      >
        {routines.length === 0 && (
          <div className="tw-mt-40 tw-text-center">
            <p className="tw-text-label-secondary tw-font-bold">No routines</p>
            <p className="tw-text-label-tertiary tw-text-15">
              Tap the &quot;New&quot; button to create one.
            </p>
          </div>
        )}

        <div
          role="button"
          className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-0"
          onClick={() => setIsSeeAll(true)}
        >
          &nbsp;
        </div>

        <ul className="tw-flex tw-flex-col tw-gap-8 tw-relative tw-z-1">
          {getSortedRoutines(routines).map((routine) => (
            <HomeRoutineCard
              key={routine.id}
              routine={routine}
              isSeeAll={isSeeAll}
            />
          ))}
        </ul>
      </div>

      {/* footer */}
      {!isSeeAll && (
        <div className="tw-grid tw-grid-flow-col tw-gap-12 tw-pt-12 tw-pb-24">
          <NewRoutineMenuItems />

          {routines.length > 3 && (
            <Button
              color="Primary"
              disabled={routines.length === 0}
              onClick={() => setIsSeeAll(true)}
            >
              See all
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
