import cx from 'classnames';
import { useRouter } from 'next/router';

import { Badge } from '@sb/design-system';
import type { Routine } from '@sb/remote-control/types';
import { getLinkToRoutinePage } from '@sbrc/utils';

import RobotLoadStatus from '../routine-list/RobotLoadStatus';

interface RoutineCardProps {
  routine: Routine.Summary;
  isSeeAll: boolean;
}

export function HomeRoutineCard({ routine, isSeeAll }: RoutineCardProps) {
  const { updatedAt, id, name } = routine;

  const { push } = useRouter();

  const onRoutineCardClick = () => {
    push(getLinkToRoutinePage(id));
  };

  const timeFormat: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
  };

  return (
    <li
      className={cx(
        'tw-h-60 tw-flex tw-items-center tw-gap-16 tw-px-16 tw-shadow-30 tw-rounded-10 tw-surface-elevated',
        isSeeAll && 'tw-surface-secondary',
      )}
    >
      <div
        className="tw-flex tw-flex-1 tw-gap-16 tw-items-center"
        role="button"
        onClick={onRoutineCardClick}
      >
        <div className="tw-flex-1">
          <div className="tw-font-medium tw-line-clamp-1">{name}</div>
          <div className="tw-line-clamp-1 tw-text-15 tw-text-label-tertiary">
            Updated {updatedAt.toLocaleDateString(undefined, timeFormat)}
          </div>
        </div>

        {routine.isTemplate && <Badge color="Primary">Template</Badge>}

        <RobotLoadStatus routineID={id} />
      </div>
    </li>
  );
}
