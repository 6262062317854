import { useRouter } from 'next/router';

import { Button, NavigationBar } from '@sb/design-system';
import type { Robot, Routine } from '@sb/remote-control/types';
import { LINK_TO_LOGOUT_PAGE } from '@sbrc/utils';

import { AppBar } from '../app-bar';

import { GripperWidget } from './GripperWidget';
import HomeMoveRobot from './HomeMoveRobot';
import HomeRoutineList from './HomeRoutineList';
import { RoutineRunningWidget } from './RoutineRunningWidget';

interface HomeViewProps {
  robot: Robot.ConvertedResponse;
  routine?: Routine.ConvertedResponse | null;
}

export default function HomeView({ robot, routine }: HomeViewProps) {
  const { push } = useRouter();

  const title = 'Home';

  return (
    <div className="tw-h-screen tw-min-h-[680px] tw-bg-gradient-to-t tw-from-metal-40 tw-to-metal-30 dark:tw-from-metal-100 dark:tw-to-gray-90">
      <NavigationBar
        contentLeft={
          <Button
            size={44}
            variant="Flat"
            className="tw-pl-0 tw-pr-8 tw-icon-28 tw-gap-0"
            onClick={() => {
              // dispatch an event that the iPad app can detect
              document.dispatchEvent(new Event('logout'));

              push(LINK_TO_LOGOUT_PAGE);
            }}
            data-testid="logout-button"
          >
            Log out
          </Button>
        }
      />

      {/* height = 100vh - var(--page--app-bar-height) - var(--page--header-height) - title's height */}
      <div
        className="
          tw-flex tw-flex-col tw-justify-center tw-h-[calc(100vh_-_52px_-_60px_-_32px)] tw-min-h-[680px] tw-max-w-1128 tw-px-20 tw-pb-60 tw-mx-auto
        "
      >
        <h1 className="tw-text-26 tw-font-medium tw-mb-32">{title}</h1>

        <div className="tw-grid tw-grid-rows-3 tw-grid-cols-6 tw-gap-24 tw-h-[540px]">
          <HomeRoutineList />

          <HomeMoveRobot robot={robot} />

          <RoutineRunningWidget routine={routine} />

          <GripperWidget robot={robot} />
        </div>
      </div>

      <AppBar robot={robot} routine={routine} />
    </div>
  );
}
