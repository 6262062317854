import HomeViewLayout from '@sbrc/components/home/HomeViewLayout';
import PrivatePage from '@sbrc/components/user-sessions/PrivatePage';

export default function HomePage() {
  return (
    <PrivatePage>
      <HomeViewLayout />
    </PrivatePage>
  );
}
