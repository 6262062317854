import cx from 'classnames';
import { useRouter } from 'next/router';
import { useMemo, useEffect, useRef } from 'react';

import type { Routine, Step } from '@sb/remote-control/types';
import { listAllSteps } from '@sb/remote-control/util/listAllSteps';
import { convertMsToHHMMSS } from '@sb/utilities';
import {
  useRobotCycleStats,
  useRobotFailureState,
  useRobotRoutineRunningState,
  useRobotStateKind,
} from '@sbrc/hooks';
import { getLinkToRoutinePage } from '@sbrc/utils';

interface RoutineRunningWidgetProps {
  routine?: Routine.ConvertedResponse | null;
}

export function RoutineRunningWidget({ routine }: RoutineRunningWidgetProps) {
  const divParentRef = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const failureState = useRobotFailureState({ isVizbot: false });
  const routineRunningState = useRobotRoutineRunningState({ isVizbot: false });
  const routineRunnerKind = useRobotStateKind({});
  const { push } = useRouter();

  const routineName = routine?.name || 'No routine loaded';
  const routineID = routine ? routine.id : null;

  const onRoutineCardClick = () => {
    push(getLinkToRoutinePage(routineID!));
  };

  // code block: routine running line
  const allSteps: Step.ConvertedSummary[] = useMemo(() => {
    return listAllSteps(routine?.steps || []);
  }, [routine]);

  const routineStepsAmount = allSteps.length || 0;

  const currentStepID: string | undefined =
    failureState?.failedStep ??
    routineRunningState?.currentStepID ??
    allSteps[0]?.id;

  const currentStep = useMemo<Step.ConvertedSummary | undefined>(() => {
    if (currentStepID) {
      return allSteps.find((step) => step.id === currentStepID);
    }

    return undefined;
  }, [allSteps, currentStepID]);

  const runningLineWidth =
    divParentRef.current !== null ? divParentRef.current.offsetWidth : 0;

  const currentStepWidth = useMemo<number>(() => {
    return runningLineWidth / routineStepsAmount;
  }, [runningLineWidth, routineStepsAmount]);

  useEffect(() => {
    if (divRef.current && routineRunnerKind === 'RoutineRunning') {
      divRef.current.style.width = '0px';

      const progressWidth =
        currentStep && currentStep?.stepPosition * currentStepWidth;

      divRef.current.style.width = `${progressWidth}px`;
    }

    if (divRef.current && routineRunnerKind !== 'RoutineRunning') {
      divRef.current.style.width = '0px';
    }
  }, [currentStep, currentStepWidth, routineRunnerKind]);

  // code block: cycles
  const cycleStats = useRobotCycleStats({});
  const cycleCount: number = cycleStats?.cycleCount ?? 0;

  const totalRuntime = useMemo(() => {
    if (!cycleStats?.runTimeSeconds) {
      return <>&mdash;</>;
    }

    return convertMsToHHMMSS(cycleStats.runTimeSeconds * 1000);
  }, [cycleStats?.runTimeSeconds]);

  const timePerCycle = useMemo(() => {
    if (!cycleStats?.runTimeSeconds) {
      return <>&mdash;</>;
    }

    const runTimeMs = cycleStats.runTimeSeconds * 1000;

    return convertMsToHHMMSS(runTimeMs / (cycleCount || 1));
  }, [cycleCount, cycleStats?.runTimeSeconds]);

  const cycles = useMemo(() => {
    if (!cycleStats?.runTimeSeconds) {
      return <>&mdash;</>;
    }

    return cycleCount;
  }, [cycleCount, cycleStats?.runTimeSeconds]);

  const cycleColumns: { id: number; title: string; value: any }[] = [
    { id: 0, title: 'Cycle time', value: totalRuntime },
    { id: 1, title: 'Cycles', value: cycles },
    { id: 2, title: 'Avg. cycle', value: timePerCycle },
  ];

  return (
    <div className="tw-col-span-2 tw-row-span-1 tw-dark tw-rounded-[32px] tw-shadow-80 tw-p-24 tw-pt-22 tw-surface-primary">
      <div
        className={cx(
          'tw-text-20 tw-font-medium tw-text-ellipsis',
          routineID ? 'tw-text-orange' : 'tw-text-label-tertiary',
        )}
        role="button"
        onClick={() => routineID && onRoutineCardClick()}
      >
        {routineName}
      </div>

      <div className="tw-flex tw-justify-between tw-gap-12 tw-my-12">
        {cycleColumns.map((column) => (
          <div key={column.id} className="tw-flex tw-flex-col tw-items-center">
            <p className="tw-text-26">{column.value}</p>
            <p className="tw-text-13 tw-text-label-secondary">{column.title}</p>
          </div>
        ))}
      </div>

      {routineID ? (
        <div
          ref={divParentRef}
          className="tw-h-16 tw-bg-orange-100 tw-rounded-10 tw-relative"
        >
          <div
            ref={divRef}
            className="tw-absolute tw-l-0 tw-r-0 tw-t-0 tw-b-0 tw-h-16 tw-rounded-10 tw-bg-orange tw-z-0"
          />

          <div className="tw-relative tw-z-1 tw-flex tw-justify-between tw-items-center tw-h-16 tw-px-6">
            {[...Array(routineStepsAmount + 1)].map((_, index) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                className={cx(
                  'tw-w-6 tw-h-6 tw-rounded-6',
                  index === routineStepsAmount
                    ? 'tw-bg-transparent'
                    : 'tw-bg-orange-90',
                )}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="tw-h-16 tw-bg-fill-primary tw-rounded-10" />
      )}
    </div>
  );
}
