import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { Button, Icon, Popper } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';
import {
  useIsRobotBraked,
  useRobotBadgeStatus,
  useRobotUnbrake,
} from '@sbrc/hooks';
import { LINK_TO_MOVE_PAGE } from '@sbrc/utils';

import RobotMenuSettings from '../robot-badge/RobotMenuSettings';

interface HomeMoveRobotwProps {
  robot: Robot.ConvertedResponse;
}

export default function HomeMoveRobot({ robot }: HomeMoveRobotwProps) {
  const { push } = useRouter();
  const { name, speedRestrictionPercentage } = robot;

  const { brakeRobot, canBrake } = useRobotUnbrake({});
  const isBraked = useIsRobotBraked({});
  const robotBadgeStatus = useRobotBadgeStatus();

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  return (
    <div className="tw-relative tw-col-span-4 tw-row-span-2">
      <div
        role="button"
        className="tw-dark tw-surface-primary tw-rounded-[32px] tw-shadow-80 tw-px-24 tw-pt-0"
        onClick={() => push(LINK_TO_MOVE_PAGE)}
      >
        <div className="tw-flex tw-items-center tw-h-[64px] tw-justify-between">
          <h3 className="tw-text-20 tw-font-medium">{name}</h3>
          <div className="tw-text-15 tw-text-label-secondary">
            {speedRestrictionPercentage * 100}%
          </div>
        </div>

        <div className="tw-m-auto -tw-mt-20 tw-w-320 tw-overflow-hidden tw-relative tw-h-[308px]">
          <Image
            priority
            src="/images/robot.jpg"
            alt="Move robot"
            width="320"
            height="308"
            className="tw-absolute -tw-z-1 -tw-top-[44px]"
          />
          <div className="tw-bg-gradient-to-t tw-from-black tw-absolute tw-h-[130px] tw-bottom-0 tw-left-0 tw-right-0 tw-z-1" />
        </div>
      </div>

      <div className="tw-absolute tw-left-24 tw-right-24 tw-bottom-24 tw-h-36 tw-grid tw-grid-cols-4 tw-gap-12">
        <Button
          color="Green"
          className="tw-col-span-2 tw-bg-green-100"
          onClick={() => push(LINK_TO_MOVE_PAGE)}
        >
          <Icon kind="tooltip" />
          <span>Move</span>
        </Button>

        <Popper
          isOpen={isSettingsOpen}
          onClose={() => setIsSettingsOpen(false)}
          offset={8}
          isDark
          content={<RobotMenuSettings robot={robot} />}
        >
          <Button
            className="tw-dark tw-w-full"
            color="Primary"
            onClick={() => setIsSettingsOpen(!isSettingsOpen)}
          >
            <Icon kind="settings" />
          </Button>
        </Popper>

        <Button
          color="Red"
          className="tw-dark tw-bg-red-100"
          onClick={brakeRobot}
          disabled={isBraked === true || !canBrake(robotBadgeStatus)}
        >
          <Icon kind="exclamationBrake" />
        </Button>
      </div>
    </div>
  );
}
